import { DARKEST_BLUE, MEDIUM_BLUE, LIGHTEST_BLUE } from "resources/style/colors";
import { ButtonNavbarProps } from "./utils/ButtonNavbarProps";
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { isNil } from 'lodash';
import { LARGE_FONT, SMALL_FONT } from "resources/style/fontSizes";
import { useContext } from "react";
import { SidebarContext } from "pages/App";

const StyledButton = styled.button<{ inSidebar: boolean }>`
    height: ${props => props.inSidebar ? "auto" : "24px" };
    border-radius: 5px;
    background: none;
    border: none;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: ${props => props.inSidebar ? LARGE_FONT: SMALL_FONT};
	cursor: pointer;
	outline: inherit;
    color: ${DARKEST_BLUE};
    transition: background-color 0.15s ease-in;
    padding: ${props => props.inSidebar ? "4px 4px 4px 4px": "0 8px 0 8px"};
    white-space: nowrap;

    :hover {
        background-color: ${MEDIUM_BLUE};
    }

    :active {
        color: ${LIGHTEST_BLUE};
        background-color: ${DARKEST_BLUE};
    }
`; 

const AbsoluteHeightLink = styled(Link)<{ inSidebar: boolean }>`
    height: ${props => props.inSidebar ? "auto" : "24px" };
`;

function ButtonNavbar(props: ButtonNavbarProps) {
    const {
        displayText,
        linkTo,
        clickHandlingFunction,
        inSidebar
    } = props;
    const { setIsSidebarOpen } = useContext(SidebarContext);

    if (!isNil(linkTo)) {
        return (
            <AbsoluteHeightLink 
                inSidebar={inSidebar ?? false} 
                to={linkTo} 
                onClick={() => setIsSidebarOpen(false)}
            >
                <StyledButton inSidebar={inSidebar ?? false}>
                    {displayText ?? ''}
                </StyledButton>
            </AbsoluteHeightLink>
        );
    }

    if (clickHandlingFunction !== undefined) {
        return (
            <StyledButton 
                onClick={() => { 
                    clickHandlingFunction();
                    setIsSidebarOpen(false);
                }} 
                inSidebar={inSidebar ?? false}
            >
                {displayText ?? ''}
            </StyledButton>
        );
    }

    return (
        <StyledButton inSidebar={inSidebar ?? false}>
            {displayText ?? ''}
        </StyledButton>      
    );
}

export default ButtonNavbar;