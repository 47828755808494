import { isNil } from "lodash";
import { AttachmentsComponentProps } from "../utils/AttachmentsComponentProps";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { useState } from "react";
import { LIGHTEST_BLUE } from "resources/style/colors";
import { ReactComponent as ChevronRight } from "resources/svg/chevron-right.svg"
import { ReactComponent as ChevronLeft } from "resources/svg/chevron-left.svg"

const StyledImage = styled.img`
    width: 100%;
    border-radius: 12px;
`;

const VideoContainer = styled.div`
    max-width: 100%;
    min-width: 100%;
    border-radius: 12px;
    
    & span {
        min-width: 100% !important;
        border-radius: 12px;
        position: relative;

        ::after {
            border-radius: 12px;
        }
    }

    & video {
        border-radius: 12px;
        position: relative;
    }
`;

const AlbumContainer = styled.div`
    width: 100%;
    position: relative;
`;

const StyledChevronRight = styled(ChevronRight)`
    width: 80px;
    height: 80px;
    margin-top: 8px;
    margin-left: 8px;

    & {
        stroke: ${LIGHTEST_BLUE};
    }
`;

const StyledChevronLeft = styled(ChevronLeft)`
    width: 80px;
    height: 80px;
    margin-top: 8px;
    margin-right: 8px;

    & {
        stroke: ${LIGHTEST_BLUE};
    }
`;

const ImageCounter = styled.div`
    border-radius: 10px;
    background-color: rgba(172, 179, 202, 0.5);
    color: ${LIGHTEST_BLUE};
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
    position: absolute;
    top: 14px;
    right: 14px;
    width: auto;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    text-align: center;
`;

const RightArrow = styled.div`
    width: 90px;
    height: 90px;
    background-color: rgba(172, 179, 202, 0.5);
    border-radius: 10px 0 0 10px;
    position: absolute;
    top: calc(50% - 45px
        );
    right: 0;
    cursor: pointer;
`;

const LeftArrow = styled.div`
    width: 90px;
    height: 90px;
    background-color: rgba(172, 179, 202, 0.5);
    border-radius: 0 10px 10px 0;
    position: absolute;
    top: calc(50% - 45px
        );
    left: 0;
    cursor: pointer;
`;

function AttachmentsComponent(props: AttachmentsComponentProps) {
    const [imageIdx, setImageIdx] = useState<number>(0);

    const { attachments } = props;

    const data = attachments.data[0];

    if (isNil(data)) {
        return (<></>)
    }

    if (data.type === 'photo') {
        return (
            <StyledImage src={data.media?.image?.src} alt='Nie znaleziono zdjęcia'/>
        );
    }

    if (data.type === 'album' ) {
        const numOfPhotos = data.subattachments?.data.length;

        return (
            <AlbumContainer>
                <ImageCounter>
                    {imageIdx + 1}/{numOfPhotos}
                </ImageCounter>
                {
                    imageIdx > 0 &&
                    <LeftArrow onClick={() => setImageIdx(imageIdx - 1)}>
                        <StyledChevronLeft />
                    </LeftArrow>
                }
                {
                    imageIdx < numOfPhotos-1 &&
                    <RightArrow onClick={() => setImageIdx(imageIdx + 1)}>
                        <StyledChevronRight />
                    </RightArrow>
                }
                <StyledImage src={data.subattachments?.data[imageIdx]?.media.image.src} alt='Nie znaleziono zdjęcia'/>
            </AlbumContainer>
        );
    }

    if (data.type === 'video_autoplay') {
        return (
            <VideoContainer>
                <ReactPlayer width="100%" height="100%" url={data.url} controls />
            </VideoContainer>
        )
    }

    return (<></>);
}

export default AttachmentsComponent;