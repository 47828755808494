import PageTitle from "components/PageTitle/PageTitle";
import PageWrapper from "components/PageWrapper/PageWrapper";
import { DARKEST_BLUE, HOVER_BLUE, LIGHTEST_BLUE } from "resources/style/colors";
import { LARGE_FONT, MEDIUM_FONT } from "resources/style/fontSizes";
import styled from "styled-components";
import { CHAPTER_1, CHAPTER_2, CHAPTER_3, CHAPTER_4, CHAPTER_5 } from "./utils/regulations";
import { ReactComponent as DownloadIcon } from "resources/svg/download.svg";
import { ReactComponent as ViewIcon } from "resources/svg/book-open.svg";
import { ReactComponent as ArrowIcon } from "resources/svg/arrow-left.svg";
import PDFReader from "components/PDFReader/PDFReader";
import { useState } from "react";


const TextSection = styled.div`
    width: 100%;
    text-align: start;
    font-size: ${LARGE_FONT};
    font-weight: 500;
    line-height: 39px;
    margin-top: 16px;
    margin-bottom: 16px;
    color: ${DARKEST_BLUE};
`;

const TextPoint = styled.div`
    width: 100%;
    text-align: start;
    font-size: ${MEDIUM_FONT};
    font-weight: 500;
    margin-bottom: 16px;
    line-height: 32px;
    color: ${DARKEST_BLUE};
    white-space: pre-wrap;
`;

const TitleButtonContainer = styled.div`
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
`;

const StyledDownloadIcon = styled(DownloadIcon)`
    width: 22px;
    height: 22px;

    & svg {
        fill: ${DARKEST_BLUE};
    }
`;

const StyledViewIcon = styled(ViewIcon)`
    width: 22px;
    height: 22px;

    & svg {
        fill: ${DARKEST_BLUE};
    }
`;

const StyledArrowIcon = styled(ArrowIcon)`
    width: 22px;
    height: 22px;
    
    & svg {
        fill: ${DARKEST_BLUE};
    }
`;

const ButtonContainer = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 5px;
    margin-top: 4px;
    border: none;
    background-color: none;
    transition: background-color 0.15s ease-in;
    display: grid;
    place-content: center;
    padding: 12px 12px 12px 12px;
    

    :hover {
        background-color: ${HOVER_BLUE};
    }

    :active{
        background-color: ${DARKEST_BLUE};

        & svg {
            fill: ${LIGHTEST_BLUE};
        }
    }
`;

const StyledLink = styled.a`
    all: unset;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    justify-content: flex-end;
`;

const ReturnButtonContainer = styled.div`
    width: 100%;
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

const pdf = require("resources/documents/Regulamin_Przystani_Jacht_Klubu_Portowiec_w_Gołuniu.pdf");

function RegulaminPrzystani() {
    const [read, setRead] = useState(false);

    if (read) {
        return (
            <PageWrapper>
                <PageTitle>
                    Regulamin Przystani Jacht Klub Portowiec w Gołuniu
                </PageTitle>
                <ReturnButtonContainer onClick={() => setRead(false)}>
                    <ButtonContainer>
                        <StyledArrowIcon />
                    </ButtonContainer>
                </ReturnButtonContainer>
                <PDFReader fileName="Regulamin_Przystani_Jacht_Klubu_Portowiec_w_Gołuniu.pdf"/>
            </PageWrapper>
        )
    }

    return (
        <PageWrapper>
            <PageTitle>
                <TitleButtonContainer>
                    <>
                        Regulamin Przystani Jacht Klub Portowiec w Gołuniu
                    </>
                    <ButtonsContainer>
                        <ButtonContainer onClick={() => setRead(true)}>
                            <StyledViewIcon />
                        </ButtonContainer>
                        <StyledLink href={pdf} download={"Regulamin_Przystani_Jacht_Klubu_Portowiec_w_Gołuniu.pdf"}>
                            <ButtonContainer title="Pobierz jako pdf">
                                <StyledDownloadIcon />
                            </ButtonContainer>
                        </StyledLink>
                    </ButtonsContainer>
                </TitleButtonContainer>
            </PageTitle>
            <TextSection>
                I Postanowienia ogólne i definicje
            </TextSection>
            {CHAPTER_1.map((position) => <TextPoint>{position}</TextPoint>)}
            <TextSection>
                II Przepisy porządkowe obowiązujące na Przystani
            </TextSection>
            {CHAPTER_2.map((position) => <TextPoint>{position}</TextPoint>)}
            <TextSection>
                III Korzystanie z pomostów Przystani
            </TextSection>
            {CHAPTER_3.map((position) => <TextPoint>{position}</TextPoint>)}
            <TextSection>
                IV Odpowiedzialność za powierzony sprzęt
            </TextSection>
            {CHAPTER_4.map((position) => <TextPoint>{position}</TextPoint>)}
            <TextSection>
                V Postanowienia końcowe
            </TextSection>
            {CHAPTER_5.map((position) => <TextPoint>{position}</TextPoint>)}
        </PageWrapper>
    );
}

export default RegulaminPrzystani;