import PageTitle from "components/PageTitle/PageTitle";
import PageWrapper from "components/PageWrapper/PageWrapper";
import { DARKEST_BLUE, DARK_BLUE, ERROR_RED, HOVER_BLUE, LIGHTEST_BLUE, MEDIUM_BLUE } from "resources/style/colors";
import styled from "styled-components";
import { ReactComponent as DownloadIcon } from "resources/svg/download.svg";
import { ReactComponent as ViewIcon } from "resources/svg/book-open.svg";
import { ReactComponent as ArrowIcon } from "resources/svg/arrow-left.svg";
import { ReactComponent as LoginIcon } from "resources/svg/chevron-right.svg";
import { ReactComponent as CrossIcon } from "resources/svg/x_error.svg"; 

import img1 from 'resources/photos/sprawyKlubowe_1.png';
import img2 from 'resources/photos/sprawyKlubowe_2.png';
import img3 from 'resources/photos/sprawyKlubowe_3.png';
import img4 from 'resources/photos/sprawyKlubowe_4.png';
import { useContext, useState } from "react";
import { LARGE_FONT, SMALL_FONT, X_MEDIUM_FONT } from "resources/style/fontSizes";
import { isNil } from "lodash";
import PDFReader from "components/PDFReader/PDFReader";
import { MobileContext } from "pages/App";
import axios from "axios";

const LoginWindow = styled.div<{ isMobile: boolean }>`
    width: 350px;
    height: 200px;
    border-radius: 15px;
    background-color: ${LIGHTEST_BLUE};
    position: absolute;
    top: ${props => props.isMobile ? "60px" : "8px"};
    padding-left: 24px;
    padding-right: 24px;
    left: ${props => props.isMobile ? "calc(50% - 199px)" : "0"};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 2;
`;

const MainContainer = styled.div`
    width: 100%;
    position: relative;
    height: auto;
`;

const StyledImage = styled.div<{ 
    width: number, 
    height: number, 
    image: string, 
    imageTop: number,
    top: number,
    right: number,
}>`
    width: ${props => `${props.width}%`};
    height: ${props => `${props.height}px`};
    border-radius: 10px;
    background: url(${props => props.image});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
`;

const LoginTitle = styled.div`
    font-size: ${LARGE_FONT};
    font-weight: 600;
    color: ${DARKEST_BLUE};
    width: 100%;
    text-align: start;
    margin-top: 16px;
    margin-bottom: 30px;
`;

const InputText = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: ${DARKEST_BLUE};
    margin-bottom: 6px;
`;

const ErrorMessage = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    margin-top: 3px;
    color: ${ERROR_RED};
`;

const LoginInput = styled.input`
    width: 274px;
    height: 32px;
    padding: 6px 0 6px 16px;
    border-width: 2px;
    border-radius: 15px;
    border-style: solid;
    border-color: ${DARK_BLUE};
    background-color: ${MEDIUM_BLUE};
    font-size: ${X_MEDIUM_FONT};
    font-weight: 500;
    color: ${DARKEST_BLUE};

    ::placeholder {
        color: ${DARK_BLUE};
    }

    :focus {
        outline: none;
        border-color: ${DARKEST_BLUE};
    }
`;

const ListContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

const ListRow = styled.div`
    width: calc(100% - 32px);
    background-color: ${LIGHTEST_BLUE};
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 16px;
    height: auto;
    min-height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${SMALL_FONT};
    color: ${DARKEST_BLUE};
    justify-content: space-between;
`;

const ButtonContainer = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 5px;
    border: none;
    background-color: none;
    transition: background-color 0.15s ease-in;
    display: grid;
    place-content: center;
    padding: 12px 12px 12px 12px;
    

    :hover {
        background-color: ${HOVER_BLUE};
    }

    :active{
        background-color: ${DARKEST_BLUE};

        & svg {
            fill: ${LIGHTEST_BLUE};
        }
    }
`;

const StyledDownloadIcon = styled(DownloadIcon)`
    width: 22px;
    height: 22px;

    & svg {
        fill: ${DARKEST_BLUE};
    }
`;

const StyledLoginIcon = styled(LoginIcon)`
    width: 22px;
    height: 22px;
    margin-right: -2px;

    & svg {
        fill: ${DARKEST_BLUE};
    }
`;

const StyledCrossIcon = styled(CrossIcon)`
    width: 16px;
    height: 16px;
    margin-bottom: -3px;

    & svg {
        stroke: ${ERROR_RED} !important;
        fill: ${ERROR_RED} !important;
    }
`;


const StyledViewIcon = styled(ViewIcon)`
    width: 22px;
    height: 22px;

    & svg {
        fill: ${DARKEST_BLUE};
    }
`;

const StyledArrowIcon = styled(ArrowIcon)`
    width: 22px;
    height: 22px;
    
    & svg {
        fill: ${DARKEST_BLUE};
    }
`;

const StyledLink = styled.a`
    all: unset;
`;

const ImagesContainer = styled.div<{ isMobile: boolean }>`
    position: absolute;
    top: -100px;
    left: 0;
    max-width: 1120px;
    width: 100%;
    height: 540px;
    display: ${props => props.isMobile ? "none" : "flex"};
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 12px;
    margin-top: 16px;
`;

const ImagesColumnContainer = styled.div`
    width: 40%;
    height: inherit;
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

const ReturnButtonContainer = styled.div`
    width: 100%;
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

const ListsContainer = styled.div<{ isMobile: boolean }>`
    width: 100%;
    display: flex;
    gap: 16px;
    flex-direction:${props => props.isMobile ? "column" : "row"};
    justify-content: center;
    align-items: flex-start;
`;

const InputButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
`;

const ErrorContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`;

const LoginButtonContainer = styled(ButtonContainer)`
    width: 30px;
    height: 30px;
    padding: 8px 8px 8px 8px;
    background-color: ${DARKEST_BLUE};
    border-radius: 15px;

    :hover {
        background-color: ${DARK_BLUE};
    }

    :active {
        background-color: ${MEDIUM_BLUE};
        
        & svg {
            stroke: ${DARKEST_BLUE};
            fill: none;
        }
    }

    & svg {
        stroke: ${LIGHTEST_BLUE};
        width: 30px;
        height: 30px;
    }
`;

const filesDocuments = [
    {
        file: require("resources/documents/STATUT_KLUBU_1994r.pdf"),
        fileName: "STATUT_KLUBU_1994r.pdf",
        displayName: `Statut Jacht Klubu "Portowiec" Gdańsk`,
    },
    {
        file: require("resources/documents/deklaracja_członkowska.pdf"),
        fileName: "deklaracja_członkowska.pdf",
        displayName: `Deklaracja przyjęcia członka Jacht Klubu "Portowiec" Gdańsk`,
    },
    {
        file: require("resources/documents/Odpis_Aktualny_KRS_0000092885.pdf"),
        fileName: "Odpis_Aktualny_KRS_0000092885.pdf",
        displayName: `KRS stowarzyszenia Jacht-Klubu "portowiec Gdańsk" -odpis aktualny`,
    },
    {
        file: require("resources/documents/regulamin_góra.pdf"),
        fileName: "regulamin_góra.pdf",
        displayName: `Regulamin korzystania z przystani i miejsca zakwaterowania członków Jacht Klubu "Portowiec " Gdańsk w Gołuniu`,
    },
];

const filesOther = [
    {
        file: require("resources/documents/Rozliczenie-godzin.pdf"),
        fileName: "Rozliczenie-godzin.pdf",
        displayName: 'Rozliczenie godzin',
    },
    {
        file: require("resources/documents/Rozliczenie-składek.pdf"),
        fileName: "Rozliczenie-składek.pdf",
        displayName: 'Rozliczenie składek',
    },
];

function SprawyKlubowe() {
    const [input, setInput] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [logged, setLogged] = useState(false);
    const [viewFile, setViewFile] = useState<string | undefined>();

    const isMobile = useContext(MobileContext);

    const handleLogin = (event?: any): void => {
        if (!isNil(event) && event.key !== "Enter") {    
            return;
        }

        axios({
            method: "post",
            url: "https://portowiecauthentication-vzjhs2uzaq-uc.a.run.app/login",
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST',
                'Content-Type': 'application/json',
            },
            withCredentials: false,
            data: {
                password: input,
            }
        })
            .then((res) => {
                const isCorrect = res.data.correct;

                if (isCorrect) {
                    setLogged(true);
                    setErrorMessage("");
                } else {
                    setErrorMessage("Błędne hasło, spróbuj ponownie");
                }
            });
    }

    const handleDocumentView = (documentName?: string): void => {
        setViewFile(documentName);
    }

    if (!logged) {
        return (
            <PageWrapper>
                <PageTitle>
                    Sprawy klubowe
                </PageTitle>
                <MainContainer>
                    <LoginWindow isMobile={isMobile}>
                        <LoginTitle>
                            Zaloguj się
                        </LoginTitle>
                        <InputText>
                            Wpisz hasło
                        </InputText>
                        <InputButtonContainer>
                            <LoginInput 
                                value={input}
                                onChange={(event) => setInput(event.target.value)}
                                placeholder="hasło"
                                type="password"
                                onKeyDown={handleLogin}
                            />
                            <LoginButtonContainer
                                onClick={() => handleLogin()}
                                title="Zaloguj się"
                            >
                                <StyledLoginIcon />
                            </LoginButtonContainer>
                        </InputButtonContainer>
                        {
                            errorMessage.length > 0 ?
                            <ErrorContainer>
                                <StyledCrossIcon />
                                <ErrorMessage>
                                    {errorMessage}
                                </ErrorMessage>
                            </ErrorContainer> :
                            <></>
                        }
                    </LoginWindow>
                    <ImagesContainer isMobile={isMobile}>
                        <StyledImage 
                            width={30}
                            height={136}
                            image={img1}
                            imageTop={0}
                            top={386}
                            right={774}
                        />
                        <StyledImage 
                            width={25}
                            height={207}
                            image={img2}
                            imageTop={0}
                            top={315}
                            right={463}
                        />
                        <ImagesColumnContainer>
                            <StyledImage 
                                width={40}
                                height={167}
                                image={img4}
                                imageTop={0}
                                top={0}
                                right={0}
                            />
                            <StyledImage 
                                width={100}
                                height={265}
                                image={img3}
                                imageTop={0}
                                top={190}
                                right={0}
                            />
                        </ImagesColumnContainer>
                    </ImagesContainer>
                </MainContainer>
            </PageWrapper>
        );
    }

    if (!isNil(viewFile)) {
        return (
            <PageWrapper>
                <PageTitle>
                    Sprawy klubowe
                </PageTitle>
                <ReturnButtonContainer>
                    <ButtonContainer
                        title="Wróć do listy plików"
                        onClick={() => handleDocumentView()}
                    >
                        <StyledArrowIcon />
                    </ButtonContainer>
                </ReturnButtonContainer>
                <PDFReader fileName={viewFile} />
            </PageWrapper>
        );
    }
    
    return (
        <PageWrapper>
            <PageTitle>
                Sprawy klubowe
            </PageTitle>
            <ListsContainer isMobile={isMobile}>
                <ListContainer>
                    {filesDocuments.map(
                        (file) => (
                            <ListRow key={file.displayName}>
                                <div>{file.displayName}</div>
                                <ButtonsContainer>
                                    <StyledLink href={file.file} download={file.fileName}>
                                        <ButtonContainer
                                            title="Pobierz dokument"
                                        >
                                            <StyledDownloadIcon />
                                        </ButtonContainer> 
                                    </StyledLink>
                                    <ButtonContainer 
                                        title="Wyświetl plik"
                                        onClick={() => handleDocumentView(file.fileName)}
                                    >
                                        <StyledViewIcon />
                                    </ButtonContainer>
                                </ButtonsContainer>
                            </ListRow>)
                    )}
                </ListContainer>
                <ListContainer>
                    {filesOther.map(
                        (file) => (
                            <ListRow key={file.displayName}>
                                <div>{file.displayName}</div>
                                <ButtonsContainer>
                                    <StyledLink href={file.file} download={file.fileName}>
                                        <ButtonContainer
                                            title="Pobierz dokument"
                                        >
                                            <StyledDownloadIcon />
                                        </ButtonContainer> 
                                    </StyledLink>
                                    <ButtonContainer 
                                        title="Wyświetl plik"
                                        onClick={() => handleDocumentView(file.fileName)}
                                    >
                                        <StyledViewIcon />
                                    </ButtonContainer>
                                </ButtonsContainer>
                            </ListRow>)
                    )}
                </ListContainer>
            </ListsContainer>
        </PageWrapper>
    );
}

export default SprawyKlubowe;