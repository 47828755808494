import ButtonNavbar from 'components/ButtonNavbar';
import { DARKEST_BLUE, DARK_BLUE, HOVER_BLUE, LIGHTEST_BLUE } from 'resources/style/colors';
import styled from 'styled-components';
import { BUTTON_PROPS } from './utils/buttonsProps';
import { ButtonNavbarProps } from 'components/ButtonNavbar';
import PortowiecLogo from 'resources/svg/portowiec_logo.svg';
import FacebookIconButton from 'components/FacebookIconButton/FacebookIconButton';
import { Link } from 'react-router-dom';
import { SMALL_FONT, XX_LARGE_FONT, X_MEDIUM_FONT } from 'resources/style/fontSizes';
import { SMALL_SCREEN } from 'resources/style/breakpoints';
import { useContext, useEffect, useState } from 'react';
import { ReactComponent as MenuIcon } from "resources/svg/menu.svg";
import { MobileContext, SidebarContext } from 'pages/App';

const MainContainer = styled.div`
    width: inherit;
    position: fixed;
    top: 0;
    z-index: 3;
`;

const LinksBar = styled.div`
    width: 100%;
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 70px;
    align-items: center;
    font-size: ${SMALL_FONT};
    background-color: ${LIGHTEST_BLUE};

    @media (max-width: ${SMALL_SCREEN}) {
        overflow-x: scroll;
        overflow-y: hidden;
        width: 100%;
        height: 32px;
        justify-content: flex-start;
        
        ::-webkit-scrollbar {
            height: 6px;
            z-index: 3;
            background: transparent;
        }

        ::-webkit-scrollbar-thumb {
            background: ${DARK_BLUE};
            border-radius: 4px;;
        }
    }
`;

const TopBar = styled.div`
    width: 100%;
    height: 63px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: ${DARKEST_BLUE};
`;

const TopBarText = styled.div`
    font-size: ${XX_LARGE_FONT};
    color: ${LIGHTEST_BLUE};
    white-space: nowrap;
`;

const LogoTopBar = styled.div<{ isMobile: boolean }>`
    width: 48px;
    height: 48px;
    margin-left: ${props => props.isMobile ? '22px' : '20px'};
    margin-right: ${props => props.isMobile ? '0' : '20px'};
`;

const HeaderMargin = styled.div`
    margin-bottom: 95px;
    width: 100%;
    position: relative;
`;

const MobileHeaderMargin = styled.div`
    margin-bottom: 76px;
    width: 100%;
    position: relative;
`;

const MobileMainContainer = styled.div`
    width: 100%;
    height: 76px;
    background-color: ${DARKEST_BLUE};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 3;
`;

const TitleLogoContainer = styled.div`
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: flex-start;
    gap: 13px;
    align-items: center;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
`;

const TitleText = styled.div`
    font-size: ${X_MEDIUM_FONT};
    line-height: 22px;
    color: ${LIGHTEST_BLUE};
`;

const StyledMenuIcon = styled(MenuIcon)`
    width: 28px;
    height: 28px;

    & svg {
        fill: ${DARKEST_BLUE};
    }
`;

const ButtonContainer = styled.div`
    width: 28px;
    height: 28px;
    border-radius: 5px;
    border: none;
    background-color: none;
    transition: background-color 0.15s ease-in;
    display: grid;
    place-content: center;
    padding: 6px 6px 6px 6px;
    margin-right: 17.5px;

    :hover {
        background-color: ${HOVER_BLUE};
    }

    :active{
        background-color: ${LIGHTEST_BLUE};

        & svg {
            stroke: ${DARKEST_BLUE};
        }
    }
`;

function Navbar() {
    const isMobile = useContext(MobileContext);
    const { isSidebarOpen, setIsSidebarOpen } = useContext(SidebarContext);

    const [isSidebarButtonDisabled, setIsSidebarButtonDisabled] = useState(false);

    const handleSidebarButtonClick = () => {
        if (isSidebarButtonDisabled) {
            return;
        }

        setIsSidebarButtonDisabled(true);
        setIsSidebarOpen(isMobile && !isSidebarOpen);
    }

    useEffect(() => {
        if (!isSidebarOpen) {
            setIsSidebarButtonDisabled(false);
        }
    }, [isSidebarOpen])


    if (isMobile) {
        return (
            <MobileHeaderMargin>
                <MobileMainContainer>
                    <TitleLogoContainer>
                        <Link to='/'>
                            <LogoTopBar isMobile={true}>
                                <img src={PortowiecLogo} alt=''/>
                            </LogoTopBar>
                        </Link>
                        <TitleContainer>
                            <TitleText>
                                JACHT KLUB
                            </TitleText>
                            <TitleText>
                                PORTOWIEC
                            </TitleText>
                        </TitleContainer>
                    </TitleLogoContainer>
                    <ButtonContainer 
                        
                        onClick={handleSidebarButtonClick}
                    >
                        <StyledMenuIcon />
                    </ButtonContainer>
                </MobileMainContainer>
            </MobileHeaderMargin>
        );
    }

    return (
        <HeaderMargin>
            <MainContainer>
                <TopBar>
                    <TopBarText>
                        JACHT KLUB
                    </TopBarText>
                    <Link to='/'>
                        <LogoTopBar isMobile={false}>
                            <img src={PortowiecLogo} alt=''/>
                        </LogoTopBar>
                    </Link>
                    <TopBarText>
                        PORTOWIEC
                    </TopBarText>
                </TopBar>
                <LinksBar>
                    {
                        BUTTON_PROPS.map((props: ButtonNavbarProps) => (
                            <ButtonNavbar key={props.displayText} {...props}/>
                        ))
                    }
                    <FacebookIconButton />
                </LinksBar>
            </MainContainer>
        </HeaderMargin>
    );
}

export default Navbar;