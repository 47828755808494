import { ButtonNavbarProps } from "components/ButtonNavbar";

export const BUTTON_PROPS: Array<ButtonNavbarProps> = [
    {
        displayText: 'Aktualności', 
        linkTo: '/aktualnosci',
    },
    {
        displayText: 'O klubie', 
        linkTo: '/o-klubie',
    },
    {
        displayText: 'Kalendarium', 
        linkTo: '/kalendarium',
    },
    {
        displayText: 'Oferta', 
        linkTo: '/oferta',
    },
    {
        displayText: 'Sprawy klubowe', 
        linkTo: '/sprawy-klubowe',
    },
    {
        displayText: 'Kontakt', 
        linkTo: '/kontakt',
    },
];