import { ButtonStartingPageProps } from "components/ButtonStartingPage";

export const BUTTON_PROPS: Array<ButtonStartingPageProps> = [
    {
        displayText: 'Sprawdź naszą ofertę',
        linkTo: '/oferta',
    },
    {
        displayText: 'Regulamin przystani',
        linkTo: '/regulamin-przystani',
    },
];