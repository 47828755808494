import { 
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import Navbar from "components/Navbar";
import Aktualnosci from "pages/Aktualnosci";
import StronaStartowa from "pages/StronaStartowa";
import OKlubie from "pages/OKlubie";
import Kalendarium from "pages/Kalendarium";
import Oferta from "pages/Oferta"
import SprawyKlubowe from "pages/SprawyKlubowe";
import Kontakt from "pages/Kontakt";
import GlobalStyle from "components/GlobalStyle/GlobalStyle";
import FullScreenContainer from "components/FullScreenContainer/FullScreenContainer";
import { createContext, useEffect, useMemo, useState, Dispatch } from "react";
import { MOBILE } from "resources/style/breakpoints";
import Sidebar from "components/Sidebar/Sidebar";
import RegulaminPrzystani from "./RegulaminPrzystani/RegulaminPrzystani";

export const MobileContext = createContext<boolean>(window.innerWidth <= MOBILE);
export const SidebarContext = createContext({
    isSidebarOpen: false,
    setIsSidebarOpen: ((isOpen: boolean) => {}) as Dispatch<boolean>,
});

function App() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const sidebarStoreValue = useMemo(() => ({
        isSidebarOpen, 
        setIsSidebarOpen,
    }), [isSidebarOpen]);

    const handleWindowSizeChange = () => {
        const isMobileWidth = window.innerWidth <= MOBILE;

        if (!isMobileWidth) {
            setIsSidebarOpen(false);
        }

        setIsMobile(isMobileWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    return (
        <MobileContext.Provider value={isMobile}>
            <SidebarContext.Provider value={sidebarStoreValue}>
                <FullScreenContainer>
                    <GlobalStyle />
                    <Router>
                        <Sidebar>
                            <Navbar />
                            <Routes>
                                <Route path="/" element={ <StronaStartowa /> }/>
                                <Route path="/aktualnosci" element={ <Aktualnosci /> }/>
                                <Route path="/o-klubie" element={ <OKlubie /> }/>
                                <Route path="/kalendarium" element={ <Kalendarium /> }/>
                                <Route path="/oferta" element={ <Oferta /> }/>
                                <Route path="/sprawy-klubowe" element={ <SprawyKlubowe /> }/>
                                <Route path="/kontakt" element={ <Kontakt /> }/>
                                <Route path="/regulamin-przystani" element={ <RegulaminPrzystani /> }/>
                            </Routes>
                        </Sidebar>
                    </Router>
                </FullScreenContainer>
            </SidebarContext.Provider>
        </MobileContext.Provider>
    );
}

export default App;