import styled from "styled-components";
import { DARKEST_BLUE, HOVER_BLUE, LIGHTEST_BLUE } from 'resources/style/colors';
import { ReactComponent as CopyconSVG } from 'resources/svg/copy_icon.svg';
import { CopyIconProps } from "./utils/CopyIconProps";

const MainContainer = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 5px;
    border: none;
    background-color: none;
    transition: background-color 0.15s ease-in;
    display: grid;
    place-content: center;
    padding: 12px 12px 12px 12px;
    

    :hover {
        background-color: ${HOVER_BLUE};
    }

    :active{
        background-color: ${DARKEST_BLUE};

        & svg {
            fill: ${LIGHTEST_BLUE};
        }
    }
`;

const Icon = styled(CopyconSVG)`
    width: 22px;
    height: 22px;

    & svg {
        fill: ${DARKEST_BLUE};
    }
`;

const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
}; 

function CopyIcon(props: CopyIconProps) {
    const  { textToCopy } = props;

    return (
        <MainContainer onClick={() => copyToClipboard(textToCopy)} title='Skopiuj pole do schowka'>
            <Icon />
        </MainContainer>
    );
}

export default CopyIcon;