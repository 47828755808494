import { MEDIUM_BLUE } from 'resources/style/colors';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        font-family: 'Montserrat', sans-serif !important;
        font-style: normal !important;
        font-smooth: always !important;
        background-color: ${MEDIUM_BLUE};
        -webkit-font-smoothing: antialiased;
    }

    div {
        touch-action: manipulation !important;
    }
`;

export default GlobalStyle;