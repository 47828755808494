import { isNil } from "lodash";
import { APIResponse } from "../types/APIResponse";
import { Post } from "../types/Post";

export const getfilteredOutPosts = (
    response: APIResponse,
): Post[] => {
    return response
        .data
        .filter((post) => !post.is_hidden)
        .filter((post) => !isNil(post.attachments) || !isNil(post.message))
        .filter((post) => {
            if (isNil(post.attachments)) {
                return true;
            }

            const attachment = post.attachments;

            if (!isNil(attachment) && !isNil(attachment.data[0])) {
                return attachment.data[0].type !== 'native_templates' && 
                    attachment.data[0].type !== 'share';
            }

            return true;
        });
}