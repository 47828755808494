export const CHAPTER_1 = [
    "1. Zarządcą Przystani Jacht Klubu Portowiec Gdańsk (zwanej dalej Przystanią) jest Stowarzyszenie Jacht Klub Portowiec Gdańsk (zwane dalej Stowarzyszeniem).",
    "2. W imieniu Stowarzyszenia na Przystani działania prowadzi wyznaczona przez Zarząd Stowarzyszenia osoba (lub osoby), zwana dalej Bosmanem. Bosman działa w oparciu o niniejszy Regulamin Przystani.",
    "3. Obszar działania obejmuje teren Przystani w miejscowości Gołuń zgodnie z zatwierdzoną lokalizacją.",
    "4. Na terenie Przystani obowiązuje zatwierdzony przez Zarząd Stowarzyszenia cennik opłat za poszczególne usługi, cennik dostępny jest u Bosmana.",
];

export const CHAPTER_2 = [
    "1. Twoja obecność na Przystani oznacza, że zapoznałeś się z Regulaminem Przystani i będziesz stosował się do jego zapisów i poleceń Bosmana.",
    "2. Przebywasz na terenie Przystani wyłącznie na własną odpowiedzialność. Jeżeli nie masz minimum 16 lat musisz przebywać na terenie Przystani pod opieką osoby dorosłej. Stowarzyszenie nie ponosi odpowiedzialności za wszelkie szkody wyrządzone na Twoim zdrowiu, życiu bądź na Twoim mieniu.",
    "3. Przystań nie posiada wyznaczonego kąpieliska. W obrębie Przystani kąpiesz się wyłącznie na własną odpowiedzialność. Zdecydowanie nie możesz się kąpać w obrębie pomostów żeglarskich oraz w obszarze podchodzenia jachtów do pomostów. Nie możesz też skakać z pomostów do wody.",
    "4. Na terenie Przystani nie możesz: \n \t a. biwakować bez zgody Bosmana, \n \t b. parkować samochodu bez zgody Bosmana, miejsce postoju wskazuje Bosman, \n \t c. korzystać z urządzeń i wyposażenia Przystani niezgodnie z przeznaczeniem, \n \t d. wędkować z pomostów żeglarskich, \n \t e. rozpalać ogniska, grilla bez zgody Bosmana",
    "5. Na teren przystani nie możesz wprowadzić psa bez smyczy. Jeżeli jesteś właścicielem rasy uznanej za groźną załóż psu kaganiec. Pies zawsze pozostaje pod nadzorem opiekuna i Ty odpowiadasz za swojego pupila.",
    "6. Na terenie Przystani nie możesz przebywać w stanie nietrzeźwości lub pod wpływem środków odurzających.",
    "7. Na terenie Przystani obowiązuję Cię zasada „pozostaw to miejsce w takim samym stanie jak je zastałeś”, posprzątaj po sobie. Nie pozostawiaj odpadków i śmieci na Przystani.",
    "8. Na terenie Przystani obowiązuję Cię cisza nocna w godz. 22.00 – 6.00",
];

export const CHAPTER_3 = [
    "1. Zawsze możesz zatrzymać swój jacht przy pomoście Przystani o ile pozwoli na to miejsce i rozmiary Twojego jachtu. Na dłuższy postój miejsce zawsze wskaże Ci Bosman.",
    "2. Zawsze zgłoś postój u Bosmana, ustal czas postoju i uiść należną opłatę.",
    "3. Pamiętaj, że Ty jako sternik odpowiadasz za prawidłowe cumowanie i zabezpieczenie jachtu na postoju. Ustaw jacht tak, aby zmieścili się jeszcze inni i postój jachtu był bezpieczny.",
    "4. Odpowiednio zabezpiecz wyposażenie jachtu w tym np. silnik zaburtowy, w szczególności przed kradzieżą gdyż nie ponosimy odpowiedzialności za straty na mieniu.",
    "5. Na pomoście Przystani możesz przebywać jeśli jesteś rezydentem, gościem opłacającym postój jachtu, członkiem załogi jachtu, uczestnikiem organizowanych przez nas regat, członkiem Stowarzyszenia lub otrzymałeś zgodę Bosmana.",
    "6. Zacumuj swój jacht w taki sposób aby Bosman w chwili zagrożenia mógł go przestawić w inne miejsce.",
    "7. Jeżeli chcesz być naszym rezydentem na cały sezon podpisz umowę i uiść opłatę za postój z góry.",
    "8. Jeżeli chcesz na Przystani przezimować swoją jednostkę skontaktuj się z Zarządem Stowarzyszenia.",
    "9. Nie zaśmiecaj i nie zanieczyszczaj wody wokół Przystani.",
    "10. Jeżeli odpływasz z pomostu Przystani poinformuj o tym Bosmana.",
];

export const CHAPTER_4 = [
    "1. Bosman wypożyczy Ci nasz sprzęt jeżeli posiadasz uprawnienia do jego użytkowania i uiścisz opłatę wg cennika.",
    "2. Możemy odmówić wydania naszego sprzętu jeżeli Bosman uzna że: \n \t a. jesteś pod wpływem alkoholu lub podobnie działającego środka \n \t b. są niekorzystne i niebezpieczne warunki pogodowe",
    "3. Jeżeli znajdziesz usterkę w wypożyczanym sprzęcie zgłoś ją do Bosmana przed wypłynięciem.",
    "4. Na wodzie dbaj o swoje i współtowarzyszy bezpieczeństwo, sprzęt wypożyczasz od nas wyłącznie na własną odpowiedzialność. Korzystaj ze sprzętu tylko zgodnie z jego przeznaczeniem. Nie ponosimy odpowiedzialności za szkody na zdrowiu, życiu i mieniu wyrządzone podczas użytkowania naszego sprzętu.",
    "5. Dbaj o wypożyczony od nas sprzęt, oddaj go w takim stanie w jakim go wypożyczyłeś. Zwróć sprzęt kompletny. Możemy zażądać wpłacenia kaucji za sprzęt, która podlega zwrotowi po zdaniu sprzętu bez zastrzeżeń Bosmana.",
    "6. Jeżeli wypożyczyłeś nasz sprzęt pływający odpowiadasz za wszelkie szkody wyrządzone na tym sprzęcie oraz tym sprzętem. Bosman wycenia szkodę i zobowiązany jesteś do jej pokrycia.",
    "7. Sprzęt znacznej wartości wypożyczamy w oparciu o dodatkową umowę najmu.",
];

export const CHAPTER_5 = [
    "1. Jeżeli nie przestrzegasz zapisów regulaminu Przystani i nie reagujesz na polecenia Bosmana, Bosman ma prawo wezwać do pomocy odpowiednie służby.",
    "2. Jeżeli jesteś świadkiem zaistnienia sytuacji awaryjnej lub powstania zagrożenia w życiu, zdrowiu, mieniu lub środowisku natychmiast zawiadom Bosmana i odpowiednie służby (Karetka, Straż Pożarna, Policja, Straż Leśna). Skorzystaj z numeru 112.",
    "3. Pamiętaj przestrzeganie regulaminu przyczynia się do stworzenia życzliwej atmosfery, wzajemnego zrozumienia i poszanowania, a ponadto zapewnienia odpowiedniego bezpieczeństwa na terenie Przystani.",
    "4. Poza opisanymi w regulaminie zasadami obowiązują Cię zawsze przepisy ochrony przeciw pożarowej oraz przestrzegania prawa na zasadach ogólnych."
];
