import styled from "styled-components";
import { PostProps } from "./utils/PostComponentProps";
import { DARKEST_BLUE } from "resources/style/colors";
import AttachmentsComponent from "./components/AttachmentsComponent";
import MessageComponent from "./components/MessageComponent";
import { MEDIUM_FONT } from "resources/style/fontSizes";

const MainContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 60px;
`;

const DateContainer = styled.div`
    width: 100%;
    text-align: start;
    color: ${DARKEST_BLUE};
    font-size: ${MEDIUM_FONT};
    margin-bottom: 12px;
`;

const StyledLink = styled.a`
    color: ${DARKEST_BLUE};
    width: 100%;
    text-align: center;
    margin-top: 6px;
`;


function PostComponent(props: PostProps) {
    const { post } = props;
    
    return (
        <MainContainer>
            <DateContainer>
                {post.created_time}
            </DateContainer>
            {
                post.message && 
                <MessageComponent 
                    message={post.message} 
                />
            }
            {
                post.attachments &&
                <AttachmentsComponent 
                    attachments={post.attachments}
                />
            }
            <StyledLink href={post.permalink_url} > 
                Zobacz post na naszym facebooku
            </StyledLink>
        </MainContainer>
    );
}

export default PostComponent;