import { useContext, useEffect, useState } from "react";
import { ACCESS_TOKEN } from "resources/variables/accessToken";
import { FANPAGE_ID } from "resources/variables/fanpageId";
import { APIResponse } from "./utils/types/APIResponse";
import { getfilteredOutPosts } from "./utils/methods/getfilteredOutPosts";
import { Post } from "./utils/types/Post";
import { PostProps } from "components/Post";
import PostComponent from "components/Post/PostComponent";
import PageWrapper from "components/PageWrapper/PageWrapper";
import PageTitle from "components/PageTitle/PageTitle";
import { formatPostsCreatedDate } from "./utils/methods/formatPostsCreatedDate";
import styled from "styled-components";
import { DARKEST_BLUE, HOVER_BLUE, LIGHTEST_BLUE, MEDIUM_BLUE } from "resources/style/colors";
import axios from "axios";
import { MEDIUM_FONT } from "resources/style/fontSizes";

import { ReactComponent as Square } from "resources/svg/square.svg";
import { ReactComponent as Grid } from "resources/svg/grid.svg";
import { MobileContext } from "pages/App";
import { MOBILE } from "resources/style/breakpoints";

const PostsContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
`;

const FlexColumn = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

const OneFlexColumn = styled(FlexColumn)<{ isMobile: boolean }>`
    width: ${props => props.isMobile ? "100%" : "70%"}
`;

const ButtonContainer = styled.div`
    width: 100%;
    height: 57px;
    padding-top: 16px;
    padding-bottom: 16px;
`;

const ButtonLoad = styled.button`
    width: 100%;
    border-style: solid;
    border-color: ${DARKEST_BLUE};
    background-color: transparent;
    color: ${DARKEST_BLUE};
    height: 57px;
    border-radius: 20px;
    font-size: 24px;
    margin-bottom: 48px;
    transition: background-color 0.3s ease-in;

    :hover {
        background-color: ${DARKEST_BLUE};
        color: ${MEDIUM_BLUE}
    }

    :active {
        filter: brightness(0.7);
        color: ${MEDIUM_BLUE}
    }
`;

const GridMenuContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: ${DARKEST_BLUE};
    font-size: ${MEDIUM_FONT};
    gap: 6px;
    margin-bottom: 16px;
`;

const GridButtonContainer = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 5px;
    border: none;
    background-color: none;
    transition: background-color 0.15s ease-in;
    display: grid;
    place-content: center;
    padding: 8px 8px 8px 8px;
    
    :hover {
        background-color: ${HOVER_BLUE};
    }

    :active{
        background-color: ${DARKEST_BLUE};

        & svg {
            fill: ${LIGHTEST_BLUE};
        }
    }
`;

const StyledSquare = styled(Square)`
    width: 22px;
    height: 22px;
    
    & svg {
        fill: ${DARKEST_BLUE};
    }

    :hover {

    }
`;

const StyledGrid = styled(Grid)`
    width: 22px;
    height: 22px;
    
    & svg {
        fill: ${DARKEST_BLUE};
    }
`;

const fetchPosts = async (link?: string): Promise<APIResponse> => {
    return await axios.get(
        link ?? `https://graph.facebook.com/v16.0/${FANPAGE_ID}/feed?fields=attachments,permalink_url,created_time,message,is_hidden,status_type&access_token=${ACCESS_TOKEN}`
    )
        .then((response) => response.data)
        .catch((error) => console.error(error));
};

function Aktualnosci() {
    const [loading, setLoading] = useState<boolean>(true);
    const [posts, setPosts] = useState<Post[]>([]);
    const [nextLink, setNextLink] = useState<string>();
    const [twoColumns, setTwoColumns] = useState<boolean>(true);

    const isMobile = useContext(MobileContext);

    const fetchAndAddToState = (link?: string) => {
        fetchPosts(link)
            .then((apiResponse) => {
                const nextLink = apiResponse.paging.next;

                setNextLink(nextLink);

                const filteredPosts = getfilteredOutPosts(apiResponse);
                const formattedPosts = formatPostsCreatedDate(filteredPosts);

                setPosts([...posts, ...formattedPosts]);
            
                setLoading(false);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        fetchAndAddToState();
    }, [])

    if (loading) {
        return (
            <PageWrapper>
                Ładowanie postów...
            </PageWrapper>
        );
    }

    return (
        <PageWrapper>
            <PageTitle>
                Aktualności
            </PageTitle>
            { isMobile ? 
                <></> :
                <GridMenuContainer>
                    Widok
                    <GridButtonContainer onClick={() => setTwoColumns(false)} >
                        <StyledSquare />
                    </GridButtonContainer>
                    <GridButtonContainer onClick={() => setTwoColumns(true)} >
                        <StyledGrid />
                    </GridButtonContainer>
                </GridMenuContainer>
            }
            {
                twoColumns && !isMobile ? 
                <PostsContainer>
                    <FlexColumn>
                        {
                            posts
                                .filter((_, idx) => idx % 2 === 0)
                                .map(
                                (post, idx) => {
                                    const props: PostProps = {
                                        post,
                                    };

                                    return (
                                        <PostComponent key={idx} {...props}/>
                                    )
                                }
                            )
                        }
                    </FlexColumn>
                    <FlexColumn>
                        {
                            posts
                                .filter((_, idx) => idx % 2 === 1)
                                .map(
                                (post, idx) => {
                                    const props: PostProps = {
                                        post,
                                    };

                                    return (
                                        <PostComponent key={idx} {...props}/>
                                    )
                                }
                            )
                        }
                    </FlexColumn>
                </PostsContainer> :
                <PostsContainer>
                    <OneFlexColumn isMobile={isMobile}>
                        {
                            posts
                                .map(
                                (post, idx) => {
                                    const props: PostProps = {
                                        post,
                                    };

                                    return (
                                        <PostComponent key={idx} {...props}/>
                                    )
                                }
                            )
                        }
                    </OneFlexColumn>
                </PostsContainer>
            }
            <ButtonContainer>
                <ButtonLoad onClick={() => fetchAndAddToState(nextLink)}>
                    Załaduj więcej postów
                </ButtonLoad>
            </ButtonContainer>
        </PageWrapper>
    );
}

export default Aktualnosci;