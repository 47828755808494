import { DARKEST_BLUE, LIGHTEST_BLUE } from "resources/style/colors";
import { ButtonStartingPageProps } from "./utils/ButtonStartingPageProps";
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledButton = styled.button`
    width: 100%;
    height: 57px;
    border-radius: 20px;
    background-color: #0000004D;
    border-style: solid;
    border-color: ${LIGHTEST_BLUE};
    font-size: 24px;
    color: ${LIGHTEST_BLUE};
    transition: background-color 0.15s ease-in;

    :hover {
        color: ${DARKEST_BLUE};
        background-color: ${LIGHTEST_BLUE};
    }
`;

const AbsoluteHeightLink = styled(Link)`
    height: 57px;
    max-width: 80%;
    width: 455px;
`;

function ButtonStartingPage(props: ButtonStartingPageProps) {
    const {
        displayText,
        linkTo,
    } = props;

    return (
        <AbsoluteHeightLink to={linkTo}>
            <StyledButton>
                {displayText ?? ''}
            </StyledButton>
        </AbsoluteHeightLink>
    );
}

export default ButtonStartingPage;