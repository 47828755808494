import styled from "styled-components";
import { FieldProps } from "./utils/FieldProps";
import { DARKER_BLUE, DARKEST_BLUE } from "resources/style/colors";
import { MEDIUM_FONT } from "resources/style/fontSizes";
import { ReactComponent as ExternalLinkIcon } from 'resources/svg/external-link.svg';
import CopyIcon from "components/CopyIcon";

const FieldContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
`;

const FieldTitle = styled.div<{ showCopyButton: boolean }>`
    text-align: start;
    font-size: ${MEDIUM_FONT};
    color: ${DARKEST_BLUE};
    width: 100%;
    margin-bottom: ${(props) => props.showCopyButton ? "0" : "12px"};
`;

const FieldValue = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const FieldText = styled.div<{ bold: boolean }>`
    text-align: start;
    font-size: ${MEDIUM_FONT};
    width: 100%;
    color: ${DARKER_BLUE};
    font-weight: ${props => props.bold ? "600" : "500"};
`;

const FieldTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const Link = styled.a`
    all:unset;
    font-size: ${MEDIUM_FONT};
    font-weight: 400;
    text-decoration: underline;
    display: flex;
    flex-direction: row;
    gap: 4px;
    justify-content: flex-start;
    align-items: flex-end;
    color: ${DARKER_BLUE};
    cursor: pointer;
`;

function Field(props: FieldProps) {
    const { value, title, showCopyButton, textToCopy, bold, addMarginToTitle, linkToMail } = props;

    return (
        <FieldContainer>
            <FieldTitle
                showCopyButton={showCopyButton && (!addMarginToTitle ?? true)}
            >
                {title}
            </FieldTitle>
            <FieldValue>
                <FieldTextContainer>
                    {value.map((value) => linkToMail? 
                        (
                            <Link href="mailto:poczta@portowiec.com?subject=portowiec">
                                <FieldText bold={bold ?? false}>
                                    {value}
                                </FieldText>
                                <ExternalLinkIcon />
                            </Link>
                        ) : 
                        (
                            <FieldText bold={bold ?? false}>
                                {value}
                            </FieldText>
                        ))}
                </FieldTextContainer>
                {showCopyButton ? <CopyIcon textToCopy={textToCopy ?? ""} /> : <></>}
            </FieldValue>
        </FieldContainer>
    );
}

export default Field;