import styled from "styled-components";
import { BUTTON_PROPS } from "./utils/buttonsProps";
import ButtonStartingPage from "components/ButtonStartingPage/ButtonStartingPage";
import { DARKEST_BLUE, LIGHTEST_BLUE } from "resources/style/colors";
import { useContext, useEffect, useState } from "react";

import backgroundImage1 from 'resources/photos/main_1.jpg';
import backgroundImage2 from 'resources/photos/main_2.jpg';
import backgroundImage3 from 'resources/photos/main_3.jpg';
import backgroundImage4 from 'resources/photos/main_4.jpg';
import backgroundImage5 from 'resources/photos/main_5.jpg';
import backgroundImage6 from 'resources/photos/main_6.jpg';
import backgroundImage7 from 'resources/photos/main_7.jpg';
import backgroundImage8 from 'resources/photos/main_8.jpg';
import backgroundImage9 from 'resources/photos/main_9.jpg';
import backgroundImage10 from 'resources/photos/main_10.jpg';
import backgroundImage11 from 'resources/photos/main_11.jpg';

import sponsorLogo1 from 'resources/photos/sendom_logo.webp';
import sponsorLogo2 from 'resources/photos/bobrowski_logo.png';
import sponsorLogo3 from 'resources/photos/wuz_logo.png';

import { MobileContext } from "pages/App";
import { X_MEDIUM_FONT, LARGE_FONT } from "resources/style/fontSizes";

const MainContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    position: relative;
    padding-top: 10vh;
`;

const BackgroundImage = styled.div<{ backgroundImage: string, opacity: number, positionTop: boolean }>`
    background-image: linear-gradient(
        180deg, 
        rgba(0, 0, 0, 0.125) 18.54%, 
        rgba(0, 0, 0, 0.28) 35.95%, 
        rgba(0, 0, 0, 0.5) 78.54%),
        url(${props => props.backgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: ${props => props.positionTop ? "top" : "center"};
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    transition: opacity 1s ease-in-out;
    opacity: ${props => props.opacity};
`;

const Title = styled.div`
    position: relative;
    font-size: 60px;
    text-align: center;
    letter-spacing: 0.4px;
    font-weight: 600;
    color: ${LIGHTEST_BLUE};
`;

const TitleLeft = styled.div`
    position: relative;
    font-size: 128px;
    text-align: center;
    letter-spacing: 0.4px;
    font-weight: 600;
    color: ${LIGHTEST_BLUE};
    left: -7vw;
`;

const TitleRightAndSloganFlex = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

const TitleRightAndSloganContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% - 18vw);
    max-width: 680px;
    top: -35px;
    margin-left: 16vw;
`;

const TitleRight = styled.div`
    width: 100%;
    font-size: 128px;
    font-weight: 600;
    letter-spacing: 0.4px;
    text-align: left;
    color: ${LIGHTEST_BLUE};
    overflow: hidden;
`;

const Slogan = styled.div`
    width: calc(100% - 12px);
    font-size: 40px;
    color: ${LIGHTEST_BLUE};
    text-align: left;
    letter-spacing: 0.4px;
    overflow: hidden;
    margin-left: 10px;
`;

const SloganCenter = styled.div`
    position: relative;
    font-size: 18px;
    line-height: 21px;
    color: ${LIGHTEST_BLUE};
    text-align: center;
    letter-spacing: 0.4px;
    top: 8px;
`;

const ButtonsContainer = styled.div<{ isMobile: boolean }>`
    width: 100%;    
    display: flex;
    flex-direction: ${props => props.isMobile ? "column" : "row"};
    justify-content: center;
    align-items: center;
    position: ${props => props.isMobile ? "absolute" : "relative"};
    ${props => props.isMobile ? "bottom: 252px;" : "top: 3vh;"}
    gap: 32px;
`;

const FooterContainer = styled.div`
    width: calc(100% - 38px);
    position: absolute;
    bottom: 0;
    background-color: ${DARKEST_BLUE};
    height: 180px;
    padding-top: 32px;
    padding-left: 19px;
    padding-right: 19px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: ${X_MEDIUM_FONT};
    color: ${LIGHTEST_BLUE};
    text-align: left;
`;

const PropertiesContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 32px;
    width: 100%;
`;

const FieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
`;

const SponsorsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    z-index: 2;
    margin-left: -8px;
`;

const SponsorsTitle = styled.div`
    text-align: center;
    color: ${LIGHTEST_BLUE};
    font-size: ${LARGE_FONT};
    width: 100%;
    z-index: 2;
    margin-top: 12vh;
    margin-bottom: 2vh;
`;

const SponsorsTitleMobile = styled(SponsorsTitle)`
    margin-top: 24px;
    margin-bottom: 4px;
    font-size: ${X_MEDIUM_FONT};
`;

const SponsorLogoImage = styled.img<{ isMobile: boolean }>`
    height: ${props => props.isMobile ? '35px' : '50px' };
    width: auto;
    margin-left: ${props => props.isMobile ? '4px' : '8px' };
`;

function StronaStartowa() {
    const [imageIdx, setImageIdx] = useState<number>(0)

    const isMobile = useContext(MobileContext);

    const backgroundImages = [
        backgroundImage1,
        backgroundImage2,
        backgroundImage3,
        backgroundImage4,
        backgroundImage5,
        backgroundImage6,
        backgroundImage7,
        backgroundImage8,
        backgroundImage9,
        backgroundImage10,
        backgroundImage11,
    ];

    useEffect(() => {
        let idx = 1;

        setInterval(() => { 
            setImageIdx(idx);
            
            idx = idx > (backgroundImages.length - 2) ? 0 : idx + 1;
        }, 4000);
    }, []);

    return (
        <>
        <MainContainer>
            {
                backgroundImages.map(
                    (backgroundImage, index) => <BackgroundImage opacity={index === imageIdx ? 1 : 0} positionTop={index === 5} backgroundImage={backgroundImage} />
                )
            }
            {
                isMobile ?
                <>
                    <Title>
                        Jacht Klub
                    </Title>
                    <Title>
                        Portowiec
                    </Title>
                    <SloganCenter>
                        klub z tradycją od 1956r.
                    </SloganCenter>
                    <ButtonsContainer isMobile={true}>
                        {
                            BUTTON_PROPS.map((props) => (
                                <ButtonStartingPage {...props} />
                            ))
                        }
                    </ButtonsContainer>
                    <FooterContainer>
                        <PropertiesContainer>
                            <FieldContainer>
                                <div>
                                    E-mail:
                                </div>
                                <div>
                                    poczta@portowiec.com
                                </div>
                            </FieldContainer>
                            <FieldContainer>
                                <div>
                                    Numer telefonu:
                                </div>
                                <div>
                                    +48 600 936 465
                                </div>
                            </FieldContainer>
                        </PropertiesContainer>
                        <SponsorsTitleMobile>
                            Wspierają nas:
                        </SponsorsTitleMobile>
                        <SponsorsContainer>
                            <a href="https://sendomsmart.pl/">
                                <SponsorLogoImage isMobile={isMobile} src={sponsorLogo1} alt="bobrowski logo" />
                            </a>
                            <a href="https://www.pomoc-bobrowski.pl/">
                                <SponsorLogoImage isMobile={isMobile} src={sponsorLogo2} alt="bobrowski logo" />
                            </a>
                            <a href="http://wuzportgdansk.pl/">
                                <SponsorLogoImage isMobile={isMobile} src={sponsorLogo3} alt="bobrowski logo" />
                            </a>
                        </SponsorsContainer>
                    </FooterContainer>
                </> :
                <>
                    <TitleLeft>
                        Jacht Klub
                    </TitleLeft>
                    <TitleRightAndSloganFlex>
                        <TitleRightAndSloganContainer>
                            <TitleRight>
                                Portowiec
                            </TitleRight>
                            <Slogan>
                                klub z tradycją od 1956r.
                            </Slogan>
                        </TitleRightAndSloganContainer>
                    </TitleRightAndSloganFlex>
                    <ButtonsContainer isMobile={false}>
                        {
                            BUTTON_PROPS.map((props) => (
                                <ButtonStartingPage {...props} />
                            ))
                        }
                    </ButtonsContainer>
                    <SponsorsTitle>
                        Wspierają nas:
                    </SponsorsTitle>
                    <SponsorsContainer>
                        <a href="https://sendomsmart.pl/">
                            <SponsorLogoImage isMobile={isMobile} src={sponsorLogo1} alt="bobrowski logo" />
                        </a>
                        <a href="https://www.pomoc-bobrowski.pl/">
                            <SponsorLogoImage isMobile={isMobile} src={sponsorLogo2} alt="bobrowski logo" />
                        </a>
                        <a href="http://wuzportgdansk.pl/">
                            <SponsorLogoImage isMobile={isMobile} src={sponsorLogo3} alt="bobrowski logo" />
                        </a>
                    </SponsorsContainer>
                </>
            }
        </MainContainer>
        </>
    );
}

export default StronaStartowa;