import PageTitle from "components/PageTitle/PageTitle";
import PageWrapper from "components/PageWrapper/PageWrapper";
import styled from "styled-components";

import img1 from 'resources/photos/oklubie_1.jpg';
import img2 from 'resources/photos/oklubie_2.jpg';
import img3 from 'resources/photos/oklubie_3.jpg';
import { TEXT_1, TEXT_2, TEXT_3, TEXT_4 } from "./utils/topText";
import { DARKEST_BLUE } from "resources/style/colors";
import { MEDIUM_FONT } from "resources/style/fontSizes";

const RowContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 40px;

    @media (max-width: 1100px) {
        gap: 15px;
    }
`;

const ColumnContainer = styled.div<{ width: number }>`
    width: ${props => `${props.width}%`};
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: start;
    white-space: pre-line;
`;

const StyledImage = styled.div<{ height: number, image: string }>`
    width: 100%;
    height: ${props => `${props.height}px`};
    border-radius: 10px;
    background-image: url(${props => props.image});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 16px;
`;

const TextWrapper = styled.div`
    width: 100%;
    padding-bottom: 30px;
    font-size: ${MEDIUM_FONT};
    line-height: 27px;
    font-weight: 500;
    color: ${DARKEST_BLUE};
`;

function OKlubie() {
    return (
        <PageWrapper>
            <PageTitle>
                O klubie
            </PageTitle>
            <RowContainer>
                <ColumnContainer width={40}>
                    <StyledImage image={img3} height={271} />
                    <StyledImage image={img1} height={225} />
                </ColumnContainer>
                <ColumnContainer width={60}>
                    <TextWrapper>
                        {TEXT_1}
                    </TextWrapper>
                    <TextWrapper>
                        {TEXT_2}
                    </TextWrapper>
                    <TextWrapper>
                        {TEXT_3}
                    </TextWrapper>
                </ColumnContainer>
            </RowContainer>
            <RowContainer>
                <ColumnContainer width={647}>
                    <TextWrapper>
                        {TEXT_4}
                    </TextWrapper>
                </ColumnContainer>
                <ColumnContainer width={443}>
                    <StyledImage image={img2} height={267} />
                </ColumnContainer>
            </RowContainer>
        </PageWrapper>
    );
}

export default OKlubie;