import styled from "styled-components";
import { SidebarProps } from "./utils/SidebarProps";
import { SidebarContext } from "pages/App";
import { useContext, useEffect, useState } from "react";
import { DARKEST_BLUE, HOVER_BLUE, LIGHTEST_BLUE } from "resources/style/colors";

import { ReactComponent as QuitIcon } from "resources/svg/x.svg";
import ButtonNavbar from 'components/ButtonNavbar';
import { ButtonNavbarProps } from 'components/ButtonNavbar';
import { BUTTON_PROPS } from "components/Navbar/utils/buttonsProps";
import FacebookIconButton from "components/FacebookIconButton/FacebookIconButton";

const MainContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    position: relative;
`;

const ChildrenContainer = styled.div<{ isOpen: boolean }>`
    width: 100%;
    height: 100vh;
`;

const SidebarContainer = styled.div<{ isOpen: boolean }>`
    width: ${props => props.isOpen ? "268px" : "0"};
    height: calc(100vh + 44px);
    position: fixed;
    top: 0;
    right: 0;
    background-color: ${LIGHTEST_BLUE};
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    transition: width 0.2s ease-in-out;
    padding-left: ${props => props.isOpen ? "32px" : "0"};
    padding-top: 32px;
    gap: 70px;
`;

const StyledQuitIcon = styled(QuitIcon)`
    width: 32px;
    height: 32px;

    & svg {
        stroke: ${DARKEST_BLUE};
    }
`;

const ButtonContainer = styled.div`
    width: 28px;
    height: 28px;
    border-radius: 5px;
    border: none;
    background-color: none;
    transition: background-color 0.15s ease-in;
    display: grid;
    place-content: center;
    padding: 6px 6px 6px 6px;

    :hover {
        background-color: ${HOVER_BLUE};
    }

    :active{
        background-color: ${LIGHTEST_BLUE};

        & svg {
            stroke: ${DARKEST_BLUE};
        }
    }
`;

const BackDrop = styled.div<{ isOpen: boolean }>`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    pointer-events: none;
    transition: background-color 0.3s linear;
    background-color: ${props => props.isOpen ? "rgba(10, 10, 10, 0.3)" : "transparent"};
`

const LinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
`;

function Sidebar(props: SidebarProps) {
    const [openSidebar, setOpenSidebar] = useState<boolean>(false);
    const [showBackdrop, setShowBackdrop] = useState<boolean>(false);
    
    const { children } = props;

    const { isSidebarOpen, setIsSidebarOpen } = useContext(SidebarContext);

    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (isSidebarOpen) {
            setShowBackdrop(true);
            setOpenSidebar(true);
        } else {
            setShowBackdrop(false);
            setOpenSidebar(false);
        }
        
        return () => clearTimeout(timer);
    }, [isSidebarOpen]);

    const [isSidebarButtonDisabled, setIsSidebarButtonDisabled] = useState(false);

    const handleSidebarButtonClick = () => {
        if (isSidebarButtonDisabled) {
            return;
        }

        setIsSidebarButtonDisabled(true);
        setIsSidebarOpen(false);
    }

    useEffect(() => {
        if (isSidebarOpen) {
            setIsSidebarButtonDisabled(false);
        }
    }, [isSidebarOpen])

    return (
        <MainContainer>
            <BackDrop isOpen={showBackdrop}> </BackDrop>
            <ChildrenContainer isOpen={openSidebar}>
                {children}
            </ChildrenContainer>
            <SidebarContainer isOpen={openSidebar}>
                <ButtonContainer onClick={handleSidebarButtonClick}>
                    <StyledQuitIcon />
                </ButtonContainer>
                <LinksContainer>
                    {
                        BUTTON_PROPS.map((props: ButtonNavbarProps) => (
                            <ButtonNavbar 
                                key={props.displayText} 
                                inSidebar={true}
                                {...props} 
                            />
                        ))
                    }
                    <FacebookIconButton inSidebar={true} />
                </LinksContainer>
            </SidebarContainer>
        </MainContainer>
    );
}

export default Sidebar;