
import { MEDIUM_BLUE } from "resources/style/colors";
import styled from 'styled-components';
import { PageWrapperProps } from "./utils/PageWrapperProps";
import { SMALL_SCREEN } from "resources/style/breakpoints";

const MainContainer = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${MEDIUM_BLUE};
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const ContentContainer = styled.div`
    width: 80%;
    max-width: 1035px;
    height: 100%;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition: width 0.8px ease-in;

    @media (max-width: ${SMALL_SCREEN}) {
        width: 90%;
    }
`;

function PageWrapper(props: PageWrapperProps) {
    const { children } = props;

    return (
        <MainContainer>
            <ContentContainer>
                { children }
            </ContentContainer>
        </MainContainer>
    );
}

export default PageWrapper;