import PageTitle from "components/PageTitle";
import PageWrapper from "components/PageWrapper";
import Field from 'components/Field';
import styled from 'styled-components';
import { ReactComponent as ExternalLinkIcon } from 'resources/svg/external-link.svg';

import img2 from "resources/photos/kontakt_2.png";
import img3 from "resources/photos/kontakt_3.png";
import { MEDIUM_FONT } from "resources/style/fontSizes";
import { DARKER_BLUE } from "resources/style/colors";
import { useContext } from "react";
import { MobileContext } from "pages/App";

const MainContainer = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 473px;
    gap: ${props => props.isMobile ? "12px" : "75px"};
    position: relative;
`;

const ColumnContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 2;
`;

const FieldSections = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 32px;
`;

const StyledImage = styled.div<{ 
    width: number, 
    height: number, 
    image: string, 
}>`
    width: ${props => `${props.width}%`};
    height: ${props => `${props.height}px`};
    border-radius: 10px;
    background: url(${props => props.image});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
`;

const ImagesContainer = styled.div<{ isMobile: boolean }>`
    position: absolute;
    top: ${props => props.isMobile ? "16px" : "0"};
    left: 0;
    max-width: 1120px;
    width: 100%;
    height: ${props => props.isMobile ? "770px" : "500px" };
    display: flex;
    flex-direction: ${props => props.isMobile ? "column" : "row"};
    justify-content: flex-end;
    align-items: flex-end;
    gap: 12px;
    margin-top: 16px;
`;

const Link = styled.a`
    all:unset;
    font-size: ${MEDIUM_FONT};
    font-weight: 400;
    text-decoration: underline;
    color: ${DARKER_BLUE};
    cursor: pointer;
`;

const LinkContainer =styled.div`
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
`;


function Kontakt() {
    const isMobile = useContext(MobileContext);

    const properties = [
        <FieldSections>
            <Field 
                title="Lokalizacja przystani"
                value={["ul. Gołuń 1", "80-406 Gołuń", ]}
                showCopyButton={false}
            />
            <Link 
                href="https://www.google.pl/maps/place/Jacht+Klub+Portowiec/@54.0139825,17.9923431,17z/data=!4m15!1m8!3m7!1s0x47027a67f97942ed:0x4a0dd7f021be238c!2zR2_FgnXFhCAxLCA4My00MDAgR2_FgnXFhA!3b1!8m2!3d54.0139825!4d17.994918!16s%2Fg%2F11pzt5x8_t!3m5!1s0x47027a664ae7b509:0xe882d58c43e1455c!8m2!3d54.0136025!4d17.9906515!16s%2Fg%2F11ddwhrdlt"
            >
            <LinkContainer>
                Zobacz na mapie
                <ExternalLinkIcon />
            </LinkContainer>
            </Link>
        </FieldSections>,
        <Field 
            title="Bosman przystani w Gołuniu"
            value={["+48 600 936 465"]}
            showCopyButton={!isMobile}
            textToCopy="+48600936465"
            
        />,
        <Field 
            title="Zarząd Jacht Klubu"
            value={["+48 535 658 838"]}
            showCopyButton={!isMobile}
            textToCopy="+48535658838"
            
        />,
        <Field 
            title="E-mail"
            linkToMail={true}
            value={["poczta@portowiec.com"]}
            showCopyButton={!isMobile}
            textToCopy="poczta@portowiec.com"
        />,
        <Field 
            title="Adres korespondencyjny"
            value={["ul. Śnieżna 1", "80-544 Gdańsk"]}
            showCopyButton={!isMobile}
            textToCopy="ul. Śnieżna, 80-544 Gdańsk"
            addMarginToTitle={true}
        />,   
        <Field 
            title="Bank"
            value={["64 2030 0045 1110 0000 0384 1220"]}
            showCopyButton={!isMobile}
            textToCopy="64 2030 0045 1110 0000 0384 1220"
        />,
        <Field 
            title="NIP"
            value={["583-26-80-166"]}
            showCopyButton={!isMobile}
            textToCopy="583-26-80-166"
        />,
        <Field 
            title="KRS"
            value={["0000092855"]}
            showCopyButton={!isMobile}
            textToCopy="0000092855"
        />,
        <Field 
            title="REGON"
            value={["190400290"]}
            showCopyButton={!isMobile}
            textToCopy="190400290"
        />
    ]

    return (
        <PageWrapper>
            <PageTitle>
                Kontakt
            </PageTitle>
            <MainContainer isMobile={isMobile}>
                {
                    isMobile ?
                    <ImagesContainer isMobile={true}>
                        <StyledImage 
                            width={35}
                            height={126}
                            image={img2}
                        />
                        <StyledImage 
                            width={100}
                            height={176}
                            image={img3}
                        />
                    </ImagesContainer> :
                    <ImagesContainer isMobile={false}>
                        <StyledImage 
                            width={20}
                            height={126}
                            image={img2}
                        />
                        <StyledImage 
                            width={40}
                            height={176}
                            image={img3}
                        />
                    </ImagesContainer>
                }
                <ColumnContainer> 
                    {
                        isMobile ?
                        (
                            <>
                                {properties[0]}
                                {properties.slice(2)}
                            </>
                        ) : 
                        (
                            <>
                                {properties[0]}
                                <FieldSections>
                                    {properties[1]}
                                    {properties[2]}
                                </FieldSections>
                                <FieldSections>
                                    {properties[3]}
                                </FieldSections>
                            </>
                        )
                    }
                </ColumnContainer>
                <ColumnContainer>
                    {
                        isMobile ?
                        (
                            <>
                                {properties[1]}
                            </>
                        ) :
                        (
                            <>
                                {properties.slice(4)}
                            </>
                        )
                    }
                </ColumnContainer>
            </MainContainer>
        </PageWrapper>
    );
}

export default Kontakt;