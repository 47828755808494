import PageTitle from "components/PageTitle";
import PageWrapper from "components/PageWrapper/PageWrapper";
import PDFReader from "components/PDFReader/PDFReader";

function Oferta() {
    return (
        <PageWrapper>
            <PageTitle>
                Oferta
            </PageTitle>
            <PDFReader fileName="Cennik.pdf" />
        </PageWrapper>
    );
}

export default Oferta;