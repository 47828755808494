import { useState } from "react";
import { MessageComponentProps } from "../utils/MessageComponentProps";
import { DARKEST_BLUE } from "resources/style/colors";
import styled from "styled-components";
import { SMALL_FONT } from "resources/style/fontSizes";

const MainContainer = styled.div`
    width: 100%;
    text-align: left;
    color: ${DARKEST_BLUE};
    font-size: ${SMALL_FONT};
    font-weight: 500;
    line-height: 24.38px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    word-wrap: break-word;
    white-space: pre-line;
    margin-bottom: 14px;
`;

const ElementsContainer = styled.div`
    width: 100%;
`;

const ExpandButton = styled.button`
    border: none;
    font-size: ${SMALL_FONT};
    font-weight: 400;
    color: ${DARKEST_BLUE};
    text-decoration: underline;
    background-color: transparent;
    padding: 0;
    margin-top: 2px;
    font-family: 'Montserrat',sans-serif;
    cursor: pointer;
`;

function MessageComponent(props: MessageComponentProps) {
    const [expandText, setExpandText] = useState<boolean>(false);

    const { message } = props;

    if (message.trim().length < 120) {
        return (
            <MainContainer>
                {message}
            </MainContainer>
        )
    }

    return (
            <MainContainer>
                <ElementsContainer>
                    {message.slice(0, 119) + (expandText ? message.slice(119) : '...')}
                </ElementsContainer>
                {
                    expandText ?
                    <ExpandButton onClick={() => setExpandText(false)}>Zobacz mniej</ExpandButton> :
                    <ExpandButton onClick={() => setExpandText(true)}>Zobacz więcej</ExpandButton>
                }
            </MainContainer>
    )
}

export default MessageComponent;