import PDFReader from "components/PDFReader";
import PageTitle from "components/PageTitle";
import PageWrapper from "components/PageWrapper";



function Kalendarium() {
    return (
        <PageWrapper>
            <PageTitle>
                Kalendarium
            </PageTitle>
            <PDFReader fileName="Kalendarium.pdf" />
        </PageWrapper>
    );
}

export default Kalendarium;