import styled from "styled-components";
import { PageTitleProps } from "./utils/PageTitleProps";
import { DARKEST_BLUE } from "resources/style/colors";
import { LARGE_FONT, X_LARGE_FONT } from "resources/style/fontSizes";
import { useContext } from "react";
import { MobileContext } from "pages/App";

const TtitleContainer = styled.div<{ isMobile: boolean }>`
    width: 100%;
    font-size: ${props => props.isMobile ? LARGE_FONT : X_LARGE_FONT};
    color: ${DARKEST_BLUE};
    text-align: start;
    margin-bottom: 14px;
    letter-spacing: 0.4px;
    font-weight: 600;
`;

function PageTitle(props: PageTitleProps) {
    const { children } = props;

    const isMobile = useContext(MobileContext);

    return (
        <TtitleContainer isMobile={isMobile}>
            {children}
        </TtitleContainer>
    );
}

export default PageTitle;