import { Post } from "pages/Aktualnosci/utils/types/Post";

export const formatPostsCreatedDate = (
    posts: Post[],
): Post[] => {
    return posts
        .map((post) => formatPostCreatedDate(post));
}

const formatPostCreatedDate = (
    post: Post
): Post => {
    const formattedDate = post.created_time
        .split('T')
        [0]
        .split('-')
        .reverse()
        .join('.');

    return {
        ...post,
        created_time: formattedDate,
    };
}