import { DARKEST_BLUE, LIGHTEST_BLUE, MEDIUM_BLUE } from 'resources/style/colors';
import { ReactComponent as FacebookIcon } from 'resources/svg/facebook_icon.svg';
import { FACEBOOK_FANPAGE_LINK } from 'resources/variables/facebookLink';
import styled from 'styled-components';
import { FacebookIconButtonProps } from './utils/FacebookIconButtonProps';


const IconContainer = styled.button<{ inSidebar: boolean }>`
    width: ${props => props.inSidebar ? "48px" : "22px"};
    height: ${props => props.inSidebar ? "48px" : "22px"};
    margin-top: ${props => props.inSidebar ? "16px": "0"};
    border-radius: 5px;
    border: none;
    background-color: transparent;
    transition: background-color 0.15s ease-in;
    display: grid;
    place-content: center;
    padding: 12px 12px 12px 12px;
    cursor: pointer;
    
    :hover {
        background-color: ${MEDIUM_BLUE};
    }

    :active{
        background-color: ${DARKEST_BLUE};

        & path {
            fill: ${LIGHTEST_BLUE};
        }
    }
`;

const Icon = styled(FacebookIcon)<{inSidebar: boolean}>`
    width: ${props => props.inSidebar ? "40px" : "22px"};
    height: ${props => props.inSidebar ? "40px" : "22px"};

    & path {
        fill: ${DARKEST_BLUE};
    }
`;

function FacebookIconButton(props: FacebookIconButtonProps) {
    const { inSidebar } = props;

    return (
        <a href={FACEBOOK_FANPAGE_LINK}>
            <IconContainer inSidebar={inSidebar ?? false}>
                <Icon  inSidebar={inSidebar ?? false} />
            </IconContainer>
        </a>
    );
}

export default FacebookIconButton;