import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import styled from "styled-components";
import { DARKEST_BLUE, LIGHTEST_BLUE, MEDIUM_BLUE } from "resources/style/colors";
import { PDFReaderProps } from "./utils/PDFReaderProps";

const MainContainer = styled.div`
    width: 100%;
    height: auto;
    margin-bottom: 16px;
`;

const StyledDocViewer = styled(DocViewer)`
    border-radius: 16px;
    width: 100%; 

    & a {
        :hover {
            background-color: ${MEDIUM_BLUE};
        }
    }

    & button {
        :hover {
            background-color: ${MEDIUM_BLUE};
        }
    }

    & path {
        fill: ${DARKEST_BLUE};
    }

    & polygon {
        fill: ${DARKEST_BLUE};
    }

    & #pdf-renderer {
        background-color: ${LIGHTEST_BLUE};
    }

    & canvas {
        fill: ${LIGHTEST_BLUE} !important;
    }
`;

function PDFReader(props: PDFReaderProps) {
    const { fileName } = props;

    const docs = [
        { uri: require(`resources/documents/${fileName}`)},
    ];

    return (
        <MainContainer>
            <StyledDocViewer
                documents={docs}
                initialActiveDocument={docs[0]}
                pluginRenderers={DocViewerRenderers}
                config = {{
                    header: {
                        disableHeader: true,
                        disableFileName: true,
                    }
                }}
                theme={{
                    primary: LIGHTEST_BLUE,
                    secondary: MEDIUM_BLUE,
                    tertiary: DARKEST_BLUE,
                    textPrimary: DARKEST_BLUE,
                }}
            />
        </MainContainer>
    );
}

export default PDFReader;